<template>
  <div class="warning-panel">
    <div style="display: flex;background-color: white">
      <van-sidebar v-if="tagList.length>0" v-model="activeKey">
        <van-sidebar-item v-for="(item,index) in tagList" v-bind:key="index" style="background-color: #FFFFFF"
                          :title="item.title" :info="item.numbers == 0 ? '' : item.numbers"
                          @click="onChange(item.title)"/>
      </van-sidebar>
      <div style="width: 100%" v-if="this.list && this.list.length>=1">
        <van-row>
          <van-col span="1">
            <div class="verticalBar">
            </div>
          </van-col>
          <van-col span="23">
            <van-card v-for="(item,index) in list" v-bind:key="index" @click="toDetail(item)"
                      style="background-color: #FFFFFF">
              <div slot="tags" style="text-align: left; font-size: 13px; margin-top: 5px">
                <van-row>
                  <span>{{ item.name }}</span>
                </van-row>
                <van-row style="margin-top: 5px">
                  <van-col span="6">
                    推送时间：
                  </van-col>
                  <van-col span="18" style="white-space: pre-line;">
                    {{ item.pushDate }}
                  </van-col>
                </van-row>
                <van-row style="margin-top: 5px">
                  <van-col span="6">
                    推送规则：
                  </van-col>
                  <van-col span="18" style="white-space: pre-line;">
                    <span class="wrap">{{ cutout(item.rule) }}</span>
                  </van-col>
                </van-row>
                <van-row style="margin-top: 5px">今日推送数据条数：{{ item.numbers }}</van-row>
              </div>
              <div slot="footer">
                <van-button size="mini">查看历史详情</van-button>
              </div>
            </van-card>
          </van-col>
          <van-divider></van-divider>
        </van-row>
      </div>
      <div v-else style="background-color: #FFFFFF;width: 100%">
        <van-empty image="error" description="没有数据"/>
      </div>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import {getInfolist} from '@/api/warningPanel';
import {getInfoIndex} from '@/api/warningPanel'

export default {
  name: "WarningPanel",
  components: {},
  data() {
    return {
      activeKey: 0,
      freshHeight: 0,
      tagList: [],
      list: [],
      time: 30 * 60 * 60 * 1000,
      guoneng: 0,
      huaneng: 0,
      huayuanxing: 0,
      jianfaxin: 0,
      jianfa: 0,
      sanbao: 0,
      jingye: 0,
      wuchan: 0,
      btnGuoneng: false,
      btnHuaneng: false,
      btnHuayuanxing: false,
      btnJianfaxin: false,
      btnJianfa: false,
      btnSanbao: false,
      btnJingye: false,
      btnWuchan: false,
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
    this.init()
  },
  watch: {},
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });

      getInfoIndex({title: this.$route.params.id}).then(res => {
        console.log(res)
        this.list = []
        if (res.length > 0) {
          this.list.push(res[0])
          this.tagList = []
          this.tagList = res
          console.log(this.list)
          Toast.success('加载成功');
        } else {
          Toast.success('暂无数据');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })

    },
    cutout(cellValue) {
      return cellValue.replace("②", "\n②")
    },
    onChange(index) {
      getInfolist({title: index}).then(res => {
        console.log(res)
        if (res.length > 0) {
          this.list = res
          console.log(this.list)
          Toast.success('加载成功');
        } else {
          Toast.success('暂无数据');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })
    },
    toDetail(to) {
      switch (to.title) {
        case "SH-13到期":
          this.$router.push({path: "/WarningPanelDetail/SH-13"});
          break
        case "SH-21失信":
          this.$router.push({path: "/WarningPanelDetail/SH-21"});
          break
        case "SH-21多次失信":
          this.$router.push({path: "/WarningPanelDetail/SH-21-1"});
          break
        case "年协到期":
          this.$router.push({path: "/WarningPanelDetail/到期"});
          break
        case "年协双章":
          this.$router.push({path: "/WarningPanelDetail/双章"});
          break
        case "到期回访":
          this.$router.push({path: "/WarningPanelDetail/01"});
          break
        case "复盘到期":
          this.$router.push({path: "/WarningPanelDetail/02"});
          break
      }
    }
  }
}
</script>

<style scoped>
.warning-panel .verticalBar {
  width: 1px;
  height: 200em;
  background: #E4E4E4;
  display: inline-block;
  margin-top: 0px;
  vertical-align: top;
  margin-right: 0px;
  margin-left: 0px;
}

</style>

<style>
.warning-panel {
  white-space: pre-wrap;
}

.warning-panel .wrap {
  /*word-wrap: break-word;*/
  /*word-break: break-all;*/
  white-space: pre-wrap;
}
</style>